import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";


//images used on page
import background from "../../images/palmtreesdark.png";
import LoadingModal from '../Modals/LoadingModal.jsx';
import AcceptWorkOrderModal from "../Modals/AcceptWorkOrderModal.jsx";


import { useCookies } from "react-cookie";

import { fetchOpenWorkOrders } from "../../fetchRequests/mechanicWorkOrderFetch.js";

import {
  handleMouseOver,
  handleMouseOut,
  successToast,
  failToast
} from "../../stylingFunctions/sharedFunctions"

import Card from 'react-bootstrap/Card';


import { getLocation } from "../../googleFunctions/locationFunctions.js";


export default function OrderBoard({mechanicLocation}) {

    const [cookie, setCookie, removeCookie] = useCookies();
    const token = cookie.mechanicToken
    const [loading, setLoading] = useState(false)
    const [workOrders, setWorkOrders] = useState(null)
    const [workOrderToAccept, setWorkOrderToAccept] = useState(null)
    const [showOrderAcceptModal, setShowOrderAcceptModal] = useState(false)

    const getOpenWorkOrders = async () => {

        setLoading(true)
        
        const MechanicCurrentLocation = await getLocation()
        
        if(!MechanicCurrentLocation){
          failToast("Could not find your location")
          return
        }
    
        const serverResponse = await fetchOpenWorkOrders(token, MechanicCurrentLocation)
    
        setLoading(false)
    
        if(!serverResponse.data){
          failToast(serverResponse.message)
          return
        }
    
        if(!serverResponse.data.work_orders){
          successToast(serverResponse.message)
          return
        }

        setWorkOrders(serverResponse.data.work_orders)
        // successToast(serverResponse.message)
        return
    }

    useEffect(() => {
        getOpenWorkOrders()
    },[])

    const populateCards = () => {
        if(workOrders){
            return workOrders.map((workOrder) => {
                return (
                    <Card
                    bg={"Light"}
                    key={"Light"}
                    text={'dark'}
                    style={{ width: '400px' }}
                    className="mb-2"
                >
                    <Card.Header>Work Order</Card.Header>
                        <Card.Body>
                        <Card.Title> New Work Order </Card.Title>
                        <Card.Text>
                        <div>
                          <strong>Customer:</strong> {workOrder.customer}
                        </div>
                        <div>
                          <strong>Customer Phone:</strong> {workOrder.customer_phone}
                        </div>
                        <div>
                          <strong>Status:</strong> {workOrder.status}
                        </div>
                        <div>
                          <strong>Time:</strong> {workOrder.time}
                        </div>
                        <div>
                          <strong>Distance:</strong> {workOrder.distance}
                        </div>
                        <div>
                          <strong>Bike Brand:</strong> {workOrder.bike.brand}
                        </div>
                        <div>
                          <strong>Bike Model:</strong> {workOrder.bike.model}
                        </div>
                        <div>
                          <strong>Customer Location:</strong> {workOrder.user_location}
                        </div>
                        <div>
                          <strong>Work Requested:</strong> {workOrder.work_requested}
                        </div>
                        <div>
                          <strong>Created:</strong> {workOrder.creation_date}
                        </div>
                        </Card.Text>
                        <button
                            className="btn btn-primary"
                            type="submit"
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            onClick={()=>{
                                setWorkOrderToAccept(workOrder._id)
                                setShowOrderAcceptModal(true)}}
                            >Accept Order
                        </button>
                    </Card.Body>
                </Card> 
                )
            })
        }
    }











    return (
        <div className="splash-screen d-flex justify-content-center align-items-center" 
            style={{ 
                backgroundImage: `url(${background})`,
                display:"flex",
                flexDirection:"column",
                overflow:"hidden",
                position:"relative" }}>
            <AcceptWorkOrderModal 
                showOrderAcceptModal={showOrderAcceptModal}
                workOrderToAccept={workOrderToAccept}
                setShowOrderAcceptModal={setShowOrderAcceptModal}
            />


          <div className='orderBoardContent'>
            {loading ? (
              <div>
                <LoadingModal message={"Getting work orders..."} />
              </div>
            ) : (
              workOrders ? (
                populateCards()
              ) : (
                <Card
                  bg={"Light"}
                  key={"Light"}
                  text={'dark'}
                  style={{ width: '400px' }}
                  className="mb-2">
                  <Card.Body>
                      <Card.Title> Work Orders </Card.Title>
                      <Card.Text>
                          No Open Work Orders Found
                      </Card.Text>
                  </Card.Body>
                </Card>
              )
            )}
          </div>
        </div>
      )
}
