import axios from "axios";


const API_URL = 'https://bike911-server.onrender.com/token';

//===================================USER LOGGING IN===================================//

//------FUNCTIONAL------//
export const fetchLoginVerify = async (accessToken) => {

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json' // Optional, depending on your API requirements
      };

    try{
        const requestSent = await axios.get(`${API_URL}/validate-token-mechanic`, {headers})
    
        if(requestSent.status === 200){
        
            const serverResponse = {
                data:requestSent.data.data.time,
                message:requestSent.data.message,
            }

            return serverResponse
        }

    }catch(err){

        if (err.response && err.response.data) {
            const message = err.response.data.message
            const serverResponse = {
                message:message
            }
            return serverResponse
        } 
    }
}
