import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

//images used on page
import background from "../../images/palmtreesdark.png";
import LoadingModal from '../Modals/LoadingModal.jsx';


//importing the logo component
import Logo from '../Logo.jsx';

import { useCookies } from "react-cookie";

import {
  handleMouseOver,
  handleMouseOut,
  failToast,
  successToast,
} from "../../stylingFunctions/sharedFunctions.js"

import Card from 'react-bootstrap/Card';

import { orderToAcceptFetch, acceptingOrderFetch } from "../../fetchRequests/mechanicWorkOrderFetch.js";

import { getLocation } from "../../googleFunctions/locationFunctions.js";

//account page component where the user can see and would be
//able to edit their account information
export default function WorkOrder({mechanicLocation}) {
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies();
  const [showEditModal, setShowEditModal] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("")

  const mechanicToken = cookie.mechanicToken
  const workOrderId = cookie.work_order_id


  const [workOrder, setWorkOrder] = useState({
    customer:null,
    customer_phone:null,
    status:null,
    time:null,
    distance:null,
    user_location:null,
    work_requested:null,
    creation_date:null,
    bike:{
      brand:null,
      model:null
    }
  })



  const getWorkOrderData = async () => {

    
    const mechanicCurrentLocation = await getLocation()
        
    if (!mechanicCurrentLocation){
      failToast("Unable to get your location, please try again")
      return
    }
    

    setLoadingMessage("Getting order data...")
    setLoading(true)


    const serverResponse = await orderToAcceptFetch(mechanicToken, workOrderId, mechanicCurrentLocation)


    setLoading(false)

    if(!serverResponse.data){
      failToast(serverResponse.message)
      //navigate("/")
      return
    }

      const dataFromFetch = serverResponse.data.order_data
      const {
        customer,
        customer_phone,
        status, 
        time, 
        distance, 
        user_location, 
        work_requested, 
        creation_date,
        bike} = dataFromFetch

      setWorkOrder({
        customer:customer,
        customer_phone:customer_phone,
        status:status,
        time:time,
        distance:distance,
        user_location:user_location,
        work_requested:work_requested,
        creation_date:creation_date,
        bike:{
          brand:bike.brand,
          model:bike.model
        }
      })
  }


  const [updateOrderCount, setUpdateOrderCount] = useState(0)

  useEffect(() =>{

    if(!mechanicLocation){
      setLoadingMessage("Getting location data...")
      setLoading(true)
    }

    if(mechanicLocation){
      if(updateOrderCount === 0){
        getWorkOrderData()
        setUpdateOrderCount( updateOrderCount + 1)
      }
    }
  }, [mechanicLocation, updateOrderCount])






  // const handleLogout = () => {
  //   removeCookie("token");
  //   navigate("/");
  // }

  const confirmSubmission = async () => {

    setLoadingMessage("Sending order confirmation...")
    setLoading(true)

    const serverResponse = await acceptingOrderFetch(mechanicToken, workOrderId)

    if(!serverResponse.data){
      failToast(serverResponse.message)
      //navigate("/")
      return
    }

    setLoading(false)

    successToast(serverResponse.message)
    navigate("/home")
  }

  const denyWorkOrder = async () => {
    navigate("/home")
  }

  return (

        <div className="splash-screen d-flex justify-content-center align-items-center" 
            style={{ 
                backgroundImage: `url(${background})`,
                display:"flex",
                flexDirection:"column",
                overflow:"hidden",
                position:"relative" }}>


          <div className='orderBoardContent'>
            {loading ? (
              <div>
                <LoadingModal message={"Getting work orders..."} />
              </div>
            ) : (
              <Card
              bg={"Light"}
              key={"Light"}
              text={'dark'}
              style={{ width: '400px' }}
              className="mb-2"
            >
              <Card.Header>Work Orders</Card.Header>

                {workOrder.status ? (
                  <Card.Body>
                    <Card.Title> <strong>Work order from:</strong> {workOrder.customer} </Card.Title>
                    <Card.Text>
                      <div>
                        <strong>Customer Phone:</strong> {workOrder.customer_phone}
                      </div>
                      <div>
                        <strong>Status:</strong> {workOrder.status}
                      </div>
                      <div>
                        <strong>Time:</strong> {workOrder.time}
                      </div>
                      <div>
                        <strong>Distance:</strong> {workOrder.distance}
                      </div>
                      <div>
                        <strong>Bike Brand:</strong> {workOrder.bike.brand}
                      </div>
                      <div>
                        <strong>Bike Model:</strong> {workOrder.bike.model}
                      </div>
                      <div>
                        <strong>Customer Location:</strong> {workOrder.user_location}
                      </div>
                      <div>
                        <strong>Work Requested:</strong> {workOrder.work_requested}
                      </div>
                      <div>
                        <strong>Created:</strong> {workOrder.creation_date}
                      </div>
                    </Card.Text>
                    <button
                      className="btn btn-primary"
                      style={{width:"30%", margin:"5px"}}
                      onClick={() => {confirmSubmission()}}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                      type="button">Accept
                    </button>
                    <button
                      className="btn btn-secondary"
                      style={{width:"30%", margin:"5px"}}
                      onClick={() => {denyWorkOrder()}}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                      type="button">Deny
                    </button>
                  </Card.Body>
                ) : (
                  <Card.Body>
                      <Card.Text>
                        Work order unavaliable
                      </Card.Text>
                  </Card.Body>
                )}
            </Card>
            )}
          </div>
        </div>
  );
}
