import axios from "axios";
import { getLog } from "../shared/loggingFunctions";

const API_URL = 'https://bike911-server.onrender.com/log';

const headers = {
    'Content-Type': 'application/json'
  };


//===================================CREATING NEW LOGGING SESSION===================================//

export const initializeLoggerFetch = async () => {

    const initialData = await getLog({
        endpoint:"/log/init",
        type:"SENDING API",
        method:"POST"
    })

    try{

        const requestSent = await axios.post(`${API_URL}/init`, initialData, {headers})
    
        if(requestSent.status === 200){
            const serverResponse = {
              data:requestSent.data.data,
              message:requestSent.data.message,
            }
    
            return serverResponse
        }
    
    }catch(error){
        if (error.response && error.response.data) {
            const message = error.response.data.message
            const serverResponse = {
                message:message
            }
    
            return serverResponse
          } 
    }
}



//===================================SENDING NEW LOG TO LOG SESSION===================================//

export const sendLogToServer = async (newLog, sessionId) => {

    try{
        const requestSent = await axios.post(`${API_URL}/?token=${sessionId}`, newLog, {headers})

    }catch(error){
        if (error.response && error.response.data) {
            const message = error.response.data.message
            const serverResponse = {
                message:message
            }
    
            return serverResponse
        } 
    }
}
