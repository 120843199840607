import moment from 'moment-timezone';
import { sendLogToServer } from '../fetchRequests/loggingFetch';
import {
    isMobile,
    isTablet,
    isDesktop,
    deviceType,
    browserName,
    browserVersion,
    engineName,
    osName,
    osVersion,
    mobileVendor,
    mobileModel,
    deviceDetect
} from 'react-device-detect';


//===================================FORMATTING AND RESOURCE GATHERING===================================//


const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === name) {
        return value;
      }
    }
    return undefined;
  };
  


const getTimeStamp = () => {
    return moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
};


const formatLogMessage = (level, message) => {
    const timestamp = getTimeStamp();
    const formattedMessage = {
        time_stamp: timestamp,
        level:level.toUpperCase(),
        message:message
    }
    return formattedMessage;
};


//===================================CREATING A NEW LOG TO SEND TO SESSION===================================//

export const createLog = async (data) => {

    const sessionId = getCookie("mechanic_session_token");

    const dataToLog = {
        content:data,
    }

    const formattedLog = JSON.stringify(formatLogMessage('info', dataToLog));

    await sendLogToServer(formattedLog, sessionId)

};


//===================================CREATING A NEW ERROR LOG TO SEND TO SESSION===================================//

export const createErrorLog = async (data) => {

    const sessionId = getCookie("mechanic_session_token");

    const dataToLog = {
        content:data,
    }

    const formattedLog = JSON.stringify(formatLogMessage('error', dataToLog));

    await sendLogToServer(formattedLog, sessionId)

};


//===================================INITIALIZING LOG SESSION===================================//

export const getLog = async (data) => {
    const dataToLog = {
        content:data,
        device_type: deviceType,
        browser_name:browserName, 
        browser_version:browserVersion, 
        engine_name: engineName,
        os_name:osName,
        os_version:osVersion,
        mobile_vendor: mobileVendor,
        mobile_model: mobileModel
    }

    const formattedLog = JSON.stringify(formatLogMessage('info', dataToLog));

    return formattedLog
}