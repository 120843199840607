import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";


//images used on page
import background from "../../images/palmtreesdark.png";
import LoadingModal from '../Modals/LoadingModal.jsx';
import CompleteWorkOrderModal from "../Modals/CompleteWorkOrderModal.jsx";
// import OrderDetailsPanel from "./OrderDetailsPanel.jsx";

//importing the logo component
import Logo from '../Logo';
import Spinner from 'react-bootstrap/Spinner';


import { useCookies } from "react-cookie";


import {
  handleMouseOver,
  handleMouseOut,
  successToast,
  failToast
} from "../../stylingFunctions/sharedFunctions"

import Card from 'react-bootstrap/Card';

import { updateLocationFetch } from "../../fetchRequests/mechanicHomeFetch.js";

import { getLocation } from "../../googleFunctions/locationFunctions.js";


export default function Home({mechanicLocation}) {
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies();
  const token = cookie.mechanicToken
  const [showCompleteWorkOrderModal, setShowCompleteWorkOrderModal] = useState(false)
  const [loading, setLoading] = useState(true);
  const [updateWheel, setUpdateWheel] = useState(true)
  const [mechanicOnSite, setMechanicOnSite] = useState(false)
  const [pendingApproval, setPendingApproval] = useState(false)
  const [acceptAlert, setAcceptAlert] = useState(false)

  const [updateWorkOrder,setUpdateWorkOrder] = useState(true)



  const [workOrder, setWorkOrder] = useState({
    customer:null,
    customer_phone:null,
    status:null,
    time:null,
    distance:null,
    user_location:null,
    work_requested:null,
    creation_date:null,
    bike:{
      brand:null,
      model:null
    }
  })


//===================================UPDATING MECHANIC LOCATION===================================//

  // @route POST /mech/update-mechanic-location

  const updateMechanicLocation = async () => {

    setUpdateWheel(true)

    const MechanicCurrentLocation = await getLocation()
    
    if(!MechanicCurrentLocation){
      failToast("Could not find your location")
      return
    }

    const serverResponse = await updateLocationFetch(token, MechanicCurrentLocation)
    setLoading(false)
    setUpdateWheel(false)

    if(!serverResponse.data){
      failToast(serverResponse.message)
      return
    }

    // if(!serverResponse.data.work_order){
    //     successToast(serverResponse.message)
    //   return
    // }

    const dataFromFetch = serverResponse.data.work_order
      const {
        customer,
        customer_phone,
        status, 
        time, 
        distance, 
        user_location, 
        work_requested, 
        creation_date,
        bike} = dataFromFetch
    
        
        setWorkOrder({
          customer:customer,
          customer_phone:customer_phone,
          status:status,
          time:time,
          distance:distance,
          user_location:user_location,
          work_requested:work_requested,
          creation_date:creation_date,
          bike:{
            brand:bike.brand,
            model:bike.model
          }
        })

        if(status === "accepted"){
          setPendingApproval(true)
          return
        }

        if(status === "in progress"){
          setPendingApproval(false)

          if(!acceptAlert){
            successToast("User has accepted work order")
          }

          setAcceptAlert(true)
          return
        }
        
        if(status === "complete"){
          setPendingApproval(false)
          setUpdateWorkOrder(false)
          return
        }
    
        if(status === "on-site"){
          setPendingApproval(false)
          setMechanicOnSite(true)
          setUpdateWorkOrder(false)
          return
        }
    
  }


  useEffect(() => {
    let interval
    
    interval = setInterval(() => {
      if(updateWorkOrder){
        updateMechanicLocation();
      }
     
    }, 15000); // 30 seconds in milliseconds
      return () => clearInterval(interval);
  }, [updateWorkOrder, acceptAlert]);


  useEffect(() => {
      updateMechanicLocation();
  },[])






























  return (
    <div className="splash-screen d-flex justify-content-center align-items-center" style = {{backgroundImage:`url(${background})`}}>
      <CompleteWorkOrderModal
        showCompleteWorkOrderModal={showCompleteWorkOrderModal}
        setShowCompleteWorkOrderModal={setShowCompleteWorkOrderModal}
        updateMechanicLocation={updateMechanicLocation}
        setWorkOrder={setWorkOrder}>
      </CompleteWorkOrderModal>
      <div className='loginPageContent'>
            {loading ? (
              <div>
                  <LoadingModal message={"Getting your work orders..."}/>
              </div>
                ) : (
                !showCompleteWorkOrderModal && (
                <Card
                  bg={"Light"}
                  key={"Light"}
                  text={'dark'}
                  style={{ width: '400px' }}
                  className="mb-2"
                >
                  <Card.Header>
                    <strong>
                      Work Order
                      {updateWheel && !pendingApproval && (
                        <Spinner 
                          animation="border" 
                          role="status"
                          style={{
                            width:"16px",
                            height:"16px"
                          }}
                        />
                      )}
                    </strong>
                    
                  </Card.Header>
                    {workOrder.status ? (
                      <>
                        <Card.Body>
                          <Card.Text>
                          <div>
                            <strong>Customer:</strong> {workOrder.customer}
                          </div>
                          <div>
                            <strong>Customer Phone:</strong> {workOrder.customer_phone}
                          </div>
                          <div>
                            <strong>Status:</strong> {workOrder.status}
                          </div>
                          <div>
                            <strong>Time:</strong> {workOrder.time}
                          </div>
                          <div>
                            <strong>Distance:</strong> {workOrder.distance}
                          </div>
                          <div>
                            <strong>Bike Brand:</strong> {workOrder.bike.brand}
                          </div>
                          <div>
                            <strong>Bike Model:</strong> {workOrder.bike.model}
                          </div>
                          <div>
                            <strong>Customer Location:</strong> {workOrder.user_location}
                          </div>
                          <div>
                            <strong>Work Requested:</strong> {workOrder.work_requested}
                          </div>
                          <div>
                            <strong>Created:</strong> {workOrder.creation_date}
                          </div>
                          {pendingApproval && (
                            <div>
                              <strong>User approval pending...</strong>
                              <Spinner 
                                animation="border" 
                                role="status"
                                style={{
                                  width:"16px",
                                  height:"16px"
                                }}
                              />
                            </div>
                          )}
                          </Card.Text>
                          {mechanicOnSite && 
                            <button
                                className="btn btn-primary"
                                type="submit"
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                onClick={()=>{setShowCompleteWorkOrderModal(true)}}
                                >Order Completed
                            </button>
                          }
                        </Card.Body>
                        {/* <OrderDetailsPanel
                          workOrder={workOrder}
                        />   */}
                      </>
                    ) : (
                      <Card.Body>
                        <Card.Text>
                          No active work order
                        </Card.Text>
                      </Card.Body>
                    )}
                </Card>)
            )}
      </div>
      
    </div>
  );
}