import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";
import LoadingModal from "./LoadingModal";
import { useNavigate } from "react-router";

import { acceptingOrderFetch } from "../../fetchRequests/mechanicWorkOrderFetch";
import {
    handleMouseOver,
    handleMouseOut,
    successToast,
    failToast
  } from "../../stylingFunctions/sharedFunctions"

export default function AcceptWorkOrderModal({
    showOrderAcceptModal,
    workOrderToAccept,
    setShowOrderAcceptModal
}) {
    const navigate = useNavigate();
    const [cookie, setCookie, removeCookie] = useCookies();
    const token = cookie.mechanicToken
    const [loading, setLoading] = useState(false);


    const confirmSubmission = async () => {
        
        setLoading(true)
    
        const serverResponse = await acceptingOrderFetch(token, workOrderToAccept)
    
        if(!serverResponse.data){
          failToast(serverResponse.message)
          //navigate("/")
          return
        }
    
        setLoading(false)
    
        successToast(serverResponse.message)
        navigate("/home")
      }
    
      const denyWorkOrder = async () => {
        setShowOrderAcceptModal(false)
      }

     


  return (
    <>
        {loading ? (
                <div>
                    <LoadingModal message={"Sending order confirmation..."}/>
                </div>
                ) : (
            <>
            <Modal
                size="sm-8"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showOrderAcceptModal}
                backdrop={false}
                keyboard={false}
                style={{
                maxWidth: "400px",
                height:"auto",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 900
                }}
            >
                <Modal.Header className="modalHeader">
                    <div style={{textAlign:"left"}}>
                        <Modal.Title 
                            className="poppins-extrabold"
                            style={{textAlign:"center"}}>ACCEPT WORK ORDER?</Modal.Title>
                    </div>                                
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        textAlign:"center",
                        marginBottom:"10px",
                        zIndex:900}}>
                        <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                            <button
                            className="btn btn-primary"
                            onClick={() => {confirmSubmission()}}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            type="button">Accept
                            </button>
                            <button
                            className="btn btn-secondary"
                            onClick={() => {denyWorkOrder()}}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            type="button">Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body> 
            </Modal>
        </>
        )}
    </>
  );
}