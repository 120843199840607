import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
//Bootstrap tool used for creating nav bar components
import Nav from 'react-bootstrap/Nav';
//Boostrap tool used to create top of page navigation bars
import Navbar from 'react-bootstrap/Navbar';
//React Bootstrap tool used for components that are hidden
//from view until toggled
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router";

//font awesome icons used
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//importing company logo
import Logo from './Logo';

import { useCookies } from "react-cookie";

import {
    failToast,
  } from "../stylingFunctions/sharedFunctions"

export default function Header({workOrderData}) {
    const navigate = useNavigate();
    //use state defining if the sidepanel is shown or not
    const [showPanel, setShowPanel] = useState(false)
    const [cookie, setCookie, removeCookie] = useCookies();

    const handleLogout = () => {
        removeCookie("mechanicToken");
        navigate("/")
    }

    const handleOrderBoardNavigation = () => {
        navigate("/order-board")
        setShowPanel(false)
    }

    const hangleHomeNavigation = () => {
        navigate("/home")
        setShowPanel(false)
    }

return (
    <>
    {['md'].map((expand) => (
        <Navbar key={expand} expand={expand} bg="dark" data-bs-theme="dark" className="fixed-top pb-4" style={{ zIndex: 1000 }} >
            <Container fluid>
                {/*  The brand containes the logo and seems to act as a container that reacts to the size of the screen */}
                <Navbar.Brand href="#" className="w-100 d-flex justify-content-center justify-content-sm-start align-items-center ">
                    <Logo style={{width: '100px'}}/>
                </Navbar.Brand>
                {/* this toggle only displays when there is not enough space for the Nav.Link elements, displaying a toggle button instead */}
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => {setShowPanel(true) }} on className="ms-auto" />
                {/* the offcanvas section defines what is hidden off canvas when their is not enough space to be displayed on screen */}
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    bg="dark" data-bs-theme="dark"
                    show={showPanel}
                >
                        <Offcanvas.Header>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className="desktopSidebarCloseButton">
                                <FontAwesomeIcon
                                icon={faTimes}
                                className="sidebarCloseButton"
                                onClick={() => {
                                    setShowPanel(false);
                                }}
                                />
                            </div>
                        </div>  
                        </Offcanvas.Header>
                        <Offcanvas.Body className="p-0">
                            <Nav className="justify-content-end flex-grow-1 pe-3 ps-4 pt-4">
                                <Nav.Link onClick={() =>{handleOrderBoardNavigation()}}
                                    className='desktopOptionsText'>
                                        Order-Board
                                </Nav.Link>
                                <Nav.Link onClick={() =>{hangleHomeNavigation()}} className='desktopOptionsText'>Home</Nav.Link>
                                <Nav.Link onClick={() =>{handleLogout()}} className='desktopOptionsText'>Logout</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    ))}
    </>
);
}

