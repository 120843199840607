import axios from "axios";
import { 
  createLog,
  createErrorLog
} from "../shared/loggingFunctions";

const API_URL = 'https://bike911-server.onrender.com';

//===================================GETTING WORK ORDER DATA FOR MECHANIC===================================//

export const orderToAcceptFetch = async (accessToken, workOrderId, mechanicLocation) => {

    //data for api logging
    const endpoint = "/mech/mechanic-order-info"
    const method = "POST"

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json' // Optional, depending on your API requirements
      };
  
      const requestBody = {mechanicLocation: mechanicLocation}

    try{ 

      //CREATE INITIAL LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"SENDING API FETCH",
        request_body:requestBody
      })

      const requestSent = await axios.post(`${API_URL}/mech/mechanic-order-info?order_id=${workOrderId}`,requestBody, {headers})
  
      if(requestSent.status === 200){
        const serverResponse = {
          data:requestSent.data.data,
          message:requestSent.data.message,
        }

        //CREATE SUCCESS LOG//
        await createLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH RESPONSE",
          response_data:serverResponse,
        })
  
        return serverResponse
      }

    }catch(error){

      if (error.response && error.response.data) {
          const message = error.response.data.message
          const serverResponse = {
              message:message
          }

          //CREATE ERROR LOG//
          await createErrorLog({
            endpoint:endpoint,
            method:method,
            type:"FETCH ERROR",
            error_data:serverResponse,
          })
          
          return serverResponse
        } 
  }
}

//===================================MECHANIC ACCEPTS WORK ORDER===================================//

export const acceptingOrderFetch = async (accessToken, workOrderId) => {

    //data for api logging
    const endpoint = "/order/accept-work-order"
    const method = "POST"

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json' // Optional, depending on your API requirements
      };
  
    try{ 

      //CREATE INITIAL LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"SENDING API FETCH",
        request_query_order_id:workOrderId
      })

      const requestSent = await axios.post(`${API_URL}/order/accept-work-order?order_id=${workOrderId}`, {}, {headers})
  
      if(requestSent.status === 200){
        const serverResponse = {
          data:requestSent.data.data,
          message:requestSent.data.message,
        }

        //CREATE SUCCESS LOG//
        await createLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH RESPONSE",
          response_data:serverResponse,
        })
  
        return serverResponse
      }

    }catch(error){

      if (error.response && error.response.data) {
          const message = error.response.data.message
          const serverResponse = {
              message:message
          }

          //CREATE ERROR LOG//
          await createErrorLog({
            endpoint:endpoint,
            method:method,
            type:"FETCH ERROR",
            error_data:serverResponse,
          })

          return serverResponse
        } 
  }
}

//===================================MECHANIC COMPLETE WORK ORDER===================================//

export const mechanicCompleteWorkOrder = async (accessToken, mechanic_review) => {

  //data for api logging
  const endpoint = "/mech/work-order-complete"
  const method = "POST"

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json' // Optional, depending on your API requirements
  };

  const requestBody = {mechanic_review:mechanic_review}
  try{

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:requestBody
    })

    const requestSent = await axios.post(`${API_URL}/mech/work-order-complete`, requestBody, {headers})
  
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      return serverResponse
    }

  }catch(error){
    if (error.response && error.response.data) {
      const message = error.response.data.message
      const serverResponse = {
          message:message
      }

      //CREATE ERROR LOG//
      await createErrorLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH ERROR",
        error_data:serverResponse,
      })

      return serverResponse
    } 
  }

}

//===================================MECHANIC COMPLETE WORK ORDER===================================//

export const fetchOpenWorkOrders = async (accessToken, mechanicLocation) => {

  //data for api logging
  const endpoint = "/mech/open-work-order"
  const method = "POST"

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json' // Optional, depending on your API requirements
  };

  const requestBody = {mechanicLocation: mechanicLocation}

  try{

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:requestBody
    })

    const requestSent = await axios.post(`${API_URL}/mech/open-work-order`, requestBody, {headers})
  
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      return serverResponse
    }

  }catch(error){
    if (error.response && error.response.data) {
      const message = error.response.data.message
      const serverResponse = {
          message:message
      }

      //CREATE ERROR LOG//
      await createErrorLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH ERROR",
        error_data:serverResponse,
      })

      return serverResponse
    } 
  }

}
