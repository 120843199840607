import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import '../src/stylesheets/main.scss';
import { BrowserRouter } from "react-router-dom";
import './index.css'
import {disableReactDevTools} from '@fvilers/disable-react-devtools'

if (process.env.NODE_ENV === 'production'){disableReactDevTools()} 

//defining the HTML element is generated by the root.render
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);