import axios from "axios";
import { 
  createLog,
  createErrorLog
} from "../shared/loggingFunctions";

const API_URL = 'https://bike911-server.onrender.com/mech';

//===================================UPDATING MECHANIC LOCATION===================================//


export const updateLocationFetch = async (accessToken, updatedLocation) => {

  //data for api logging
  const endpoint = "/mech/update-mechanic-location"
  const method = "POST"

  const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json' // Optional, depending on your API requirements
    };

  try{ 


    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:updatedLocation
    })

    const requestSent = await axios.post(
      `${API_URL}/update-mechanic-location`, //request url 
      updatedLocation, //request body
      {headers} //request header
    );

    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      return serverResponse
    }

  }catch(error){

    if (error.response && error.response.data) {
        const message = error.response.data.message
        const serverResponse = {
            message:message
        }

        //CREATE ERROR LOG//
        await createErrorLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH ERROR",
          error_data:serverResponse,
        })


        return serverResponse
      } 
}
}
