import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';
import { useCookies } from "react-cookie";
import LoadingModal from "./LoadingModal";

import { mechanicCompleteWorkOrder } from "../../fetchRequests/mechanicWorkOrderFetch";

import {
    handleMouseOver,
    handleMouseOut,
    successToast,
    failToast
  } from "../../stylingFunctions/sharedFunctions"

export default function CompleteWorkOrderModal({
    showCompleteWorkOrderModal,
    setShowCompleteWorkOrderModal,
    updateMechanicLocation,
    setWorkOrder}) {

    const [cookie, setCookie, removeCookie] = useCookies();
    const token = cookie.mechanicToken
    const [orderFeedback, setOrderFeedback] = useState("")
    const [loading, setLoading] = useState(false);


    const completeWorkOrder = async () => {

        setLoading(true)

        const customer_feedback = orderFeedback
        

        const serverResponse = await mechanicCompleteWorkOrder(token, customer_feedback)
                
        setLoading(false)

        if(!serverResponse.data){
            failToast(serverResponse.message)
            return
        }

        successToast(serverResponse.message)

        setShowCompleteWorkOrderModal(false)

        updateMechanicLocation()

        setWorkOrder({
            customer:null,
            customer_phone:null,
            status:null,
            time:null,
            distance:null,
            user_location:null,
            work_requested:null,
            creation_date:null
        })

        setOrderFeedback("")

        return
        
        //IN CASE OF UNAITHORIZED
        //   failToast(serverResponse.message)
        //   navigate("/")
        //   return
        // }
      };

      const handleInputChange = (e) => {
        setOrderFeedback(e);
      };

  return (
    <>
        {loading ? (
                <div>
                    <LoadingModal message={"Submitting completed work order..."}/>
                </div>
                ) : (
            <Modal
                size="sm-8"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showCompleteWorkOrderModal}
                backdrop={false}
                keyboard={false}
                style={{
                maxWidth: "400px",
                height:"auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 900
                }}
            >
                <Modal.Header className="modalHeader">
                    <div style={{textAlign:"left"}}>
                        <Modal.Title 
                            className="poppins-extrabold"
                            style={{textAlign:"center"}}>WORK ORDER IS COMPLETE</Modal.Title>
                    </div>                                
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        textAlign:"center",
                        marginBottom:"10px"}}>
                        <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                        <div className="poppins-bold">Please leave feedback about your work order</div>
                        <div className="">
                            <input
                                id="model"
                                className={`form-control`}
                                type="text"
                                placeholder=''
                                value={orderFeedback}
                                onChange={(e) => handleInputChange(e.target.value)}
                                />
                        </div>
                            <button
                            className="btn btn-primary"
                            onClick={() => {completeWorkOrder()}}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            type="button">Submit Information
                            </button>
                        </div>
                    </div>
                </Modal.Body> 
            </Modal>
        )}
    </>
  );
}
