import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';


export default function LoadingModal({message}) {

  return (
    <>
      <Modal
        size="sm-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        backdrop={false}
        keyboard={false}
        style={{
          maxWidth: "400px",
          maxHeight: "300px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 900
        }}
      >
          <Modal.Body>
                <div style={{
                  alignItems:"center",
                  justifyContent:"center",
                  textAlign:"center",
                  marginTop:"10px",
                  marginBottom:"10px"}}>
                  <Spinner animation="border" role="status"></Spinner>
                  <div className="poppins-bold"
                    style={{marginTop:"10px"}}>
                      {message}
                  </div>
                </div>
          </Modal.Body>      
      </Modal>
    </>
  );
}
