import axios from "axios";
import { 
    createLog,
    createErrorLog
} from "../shared/loggingFunctions";

const API_URL = 'https://bike911-server.onrender.com/auth';

const headers = {
  'Content-Type': 'application/json'
};

//===================================USER LOGGING IN===================================//

//------FUNCTIONAL------//
export const mechanicLoginFetch = async (requestData) => {



    //data for api logging
    const endpoint = "/auth/mechanic-login"
    const method = "POST"




    try{

        //CREATE INITIAL LOG//
        await createLog({
            endpoint:endpoint,
            method:method,
            type:"SENDING API FETCH",
            request_body:requestData.email
        })


        const requestSent = await axios.post(`${API_URL}/mechanic-login`, requestData, {headers})
    
        if(requestSent.status === 200){
            const expirationInSeconds = 3600
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + (expirationInSeconds * 1000));
        
            const serverResponse = {
                data:requestSent.data.data.access_token,
                message:requestSent.data.message,
                expiration:expirationDate
            }

            //CREATE SUCCESS LOG//
            await createLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH RESPONSE",
                response_data:serverResponse,
            })


            return serverResponse
        }

    }catch(err){

        if (err.response && err.response.data) {
            const message = err.response.data.message
            const serverResponse = {
                message:message
            }

            //CREATE ERROR LOG//
            await createErrorLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH ERROR",
                error_data:serverResponse,
            })

            
            return serverResponse
        } 
    }
}




//===================================USER LOGGING IN WITH TEMP TOKEN===================================//

//------FUNCTIONAL------//
export const tokenLoginFetch = async (login_token, sessionId) => {

    //data for api logging
    const endpoint = "/auth/token-login"
    const method = "POST"

    const requestBody = {
        login_token:login_token,
        session_token: sessionId
    }


    try{

        //CREATE INITIAL LOG//
        await createLog({
            endpoint:endpoint,
            method:method,
            type:"SENDING API FETCH",
            request_body:{
                login_token:login_token,
                session_token: sessionId
            }
        })


        const requestSent = await axios.post(`${API_URL}/token-login`, requestBody, {headers})
    
        if(requestSent.status === 200){
            const expirationInSeconds = 3600
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + (expirationInSeconds * 1000));
        
            const serverResponse = {
                data:requestSent.data.data.access_token,
                message:requestSent.data.message,
                expiration:expirationDate
            }

            //CREATE SUCCESS LOG//
            await createLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH RESPONSE",
                response_data:serverResponse,
            })


            return serverResponse
        }

    }catch(err){

        if (err.response && err.response.data) {
            const message = err.response.data.message
            const serverResponse = {
                message:message
            }

            //CREATE ERROR LOG//
            await createErrorLog({
                endpoint:endpoint,
                method:method,
                type:"FETCH ERROR",
                error_data:serverResponse,
            })

            
            return serverResponse
        } 
    }
}
