import React, { useState } from 'react'
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import '../../stylesheets/main.scss';

//Node package that allows for setting cookies in the browser
import { useCookies } from "react-cookie";

//importing required images
import background from "../../images/palmtreesdark.png"
import LoadingModal from '../Modals/LoadingModal.jsx';

//importing child components
import Logo from '../Logo';

//importing styling functions for buttons
import {
    handleMouseOver,
    handleMouseOut,
    failToast,
    successToast
} from "../../stylingFunctions/sharedFunctions"

import { mechanicLoginFetch } from '../../fetchRequests/mechanicLoginFetch.js';

export default function Login() {
    const navigate = useNavigate();

    const [cookie, setCookie, removeCookie] = useCookies();

    const sessionId = cookie.mechanic_session_token


    const [loading, setLoading] = useState(false)
    
    const [loginForm, setLoginForm] = useState(
        {
          email:null,
          password:null,
        }
    )

    const updateForm = (value) => {
        return setLoginForm((prev) => {
          return {...prev, ...value}
        })
    }

//===================================SENDING USER LOGIN REQUEST===================================//

    const confirmSubmission = async () => {
        setLoading(true)

        if (!loginForm.email) {
            failToast("Email is required");
            setLoading(false)
            return;
        }

        if(!loginForm.password){
            failToast("Password is required");
            setLoading(false)
            return;
        }

        //defining a variable with values entered in the input fields of the HTML
        const mechanicLogin = {
            email: loginForm.email,
            password: loginForm.password,
            session_token:sessionId
        };

        const loginAttempt = await mechanicLoginFetch(mechanicLogin)

        setLoading(false)

        setLoginForm(
            {
                email:null,
                password:null,
            }
        )

        if(!loginAttempt.data){
            failToast(loginAttempt.message)
            return
        }

        setCookie('mechanicToken', loginAttempt.data, {
        path: '/',
        expires: loginAttempt.expiration,
        secure: true,
        sameSite: 'none',
        });

        navigate("/home")
        return 
    };

//===================================JSX RETURN===================================//

  return (
    <div className="splash-screen d-flex justify-content-center align-items-center" style = {{backgroundImage:`url(${background})`}}>
                <div className='loginPageContent'>
                    <Logo style={{width: '100px'}} />
                    {loading ? (
                        <div>
                            <LoadingModal message={"Logging you in..."}/>
                        </div>
                    ) : (
                        <>
                            <form onSubmit={confirmSubmission} autoComplete="off" style={{width:"100%", maxWidth:"350px"}}>
                                <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                                    <div className="">
                                        <input
                                            id="email"
                                            className="form-control"
                                            type="email"
                                            placeholder='Email'
                                            autoComplete='new-email'
                                            value={loginForm.email}
                                            onChange={(e) => updateForm({email: e.target.value})}
                                            required
                                            >
                                        </input>
                                    </div>
                                    <div className="">
                                        <input
                                            id="password"
                                            className="form-control"
                                            type="password"
                                            placeholder='Password'
                                            autoComplete='new-password'
                                            value={loginForm.password}
                                            onChange={(e) => updateForm({password: e.target.value})}
                                            required
                                            >
                                        </input>
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        onMouseOver={handleMouseOver}
                                        onMouseOut={handleMouseOut}
                                        >Login
                                    </button>
                                </div>
                            </form>
                            <div className="text-center">
                                <p className='registerText poppins-medium'>Don't have an account?</p>
                                <Link 
                                    to="/register" 
                                    className='registerText poppins-medium'>
                                        Sign up
                                </Link>
                            </div>  
                        </>      
                    )}
                </div>
    </div>
)
}
