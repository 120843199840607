// import './App.css';
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../src/stylesheets/main.scss';
import './App.css'



//COMPONENTS
import Login from './components/LoginComponents/Login';
import OrderLogin from "./components/LoginComponents/OrderLogin";
import Home from "./components/MechanicComponents/Home";
import WorkOrder from "./components/MechanicComponents/WorkOrder";
import Header from "./components/Header";
import OrderBoard from "./components/MechanicComponents/OrderBoard";


import { useCookies } from "react-cookie";


import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  failToast,
  successToast
} from "./stylingFunctions/sharedFunctions"

import { fetchLoginVerify } from "./fetchRequests/tokenFetch";

import { initializeLoggerFetch } from "./fetchRequests/loggingFetch.js";


function App() {

  const navigate = useNavigate();
  const location = useLocation();


  const [cookie, setCookie, removeCookie] = useCookies();

  const token = cookie.mechanicToken

  const sessionId = cookie.mechanic_session_token


  const [mechanicLocation, setMechanicLocation] = useState(null)

  const hideHeaderRoutes = ["/", "/login/:work_order_id"];

  const shouldShowHeader = !hideHeaderRoutes.some(route => location.pathname === route || location.pathname.startsWith("/login/"));


  //===================================VERIFY LOGIN TOKEN ON NAVIGATION===================================//

  const verifyLogin = async () => {
    
    const serverResponse = await fetchLoginVerify(token);

    if(!serverResponse.data){
      navigate("/")
      failToast(serverResponse.message)
    }
  }

  useEffect( () =>{
    if(location.pathname === "/" || 
      location.pathname.startsWith("/login") ){
      return
    }

    if(token){
      verifyLogin()
      return
    }

    navigate("/")

  },[location.pathname, navigate])




  //===================================WATCHING MECHANICS LOCATION===================================//

  const geolocationOptions = {
    enableHighAccuracy: true,
    maximumAge: 25,
    timeout: 10000,
    wifi: true,
    accuracy:50
  };
  
  //function used to update the user location should it change
  if ("geolocation" in navigator) {
    navigator.geolocation.watchPosition(success, error, geolocationOptions);
  } else {
    failToast("Geolocation not supported");
  }
  function success(position) {
    if(position.coords.accuracy <= 50){
      setMechanicLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }else{
      console.log("innacurate location on watch postion")
    }
  }
  function error() {
    console.log("Unable to update your location");
  }

  




//===================================INITIALIZE LOGGER===================================//

const getLoggerSession = async () => {
  const requestSent = await initializeLoggerFetch()

  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 86400 * 1000); // 86400 seconds * 1000 milliseconds


  setCookie('mechanic_session_token', requestSent.data, {
    path: '/',
    expires: expirationDate,
    secure: true,
    sameSite: 'strict',
    });

}

useEffect(() => {
  if(!sessionId){
    getLoggerSession()
  }
},[])



//===================================HANDLING WEBSITE CLOSE===================================//

useEffect(() => {
  const handleBeforeUnload = (event) => {
    // Perform cleanup or specific actions before page is unloaded or refreshed
    console.log('Performing cleanup before unloading or refreshing');
    // Example: Prompt user with a confirmation dialog if needed
    event.preventDefault();
    event.returnValue = '';
  };

  const handleUnload = () => {
    // Perform actions that should only occur when the page is closed, not refreshed
    console.log('Page is being unloaded');
    // removeCookie("session_token");
    // Example: Additional cleanup or final actions
  };

  // Add event listeners
  window.addEventListener('beforeunload', handleBeforeUnload);
  window.addEventListener('unload', handleUnload);

  // Cleanup: Remove event listeners on component unmount
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('unload', handleUnload);
  };
}, []);










  //===================================ROUTES===================================//

  return (
    <div className="App">
      <ToastContainer />
      {shouldShowHeader && <Header />}
      <Routes>
        <Route exact path="/" element={<Login/>} />
        <Route exact path="/login/:work_order_id/:login_token" element={<OrderLogin/>} />
        <Route exact path="/home" element={<Home mechanicLocation={mechanicLocation}/>} />
        <Route exact path="/new-work-order" element={<WorkOrder mechanicLocation={mechanicLocation}/>} />
        <Route exact path="/order-board" element={<OrderBoard mechanicLocation={mechanicLocation}/>} />
      </Routes>
    </div>
  );
}

export default App;